import { PageProps } from 'gatsby';
import * as React from 'react';
import { MDXProvider } from '@mdx-js/react';

import { getMDXComponentsConfig } from 'src/config';

import { Site } from '../components/Site';

// TODO: create seo mapping function
// const seo: SeoObject = {
//   title: 'Home',
// };

// NOTE: this is not used on index route
const Page: React.FC<PageProps> = (props: PageProps) => {
  const { children, location, pageContext, path } = props;
  // NOTE: mounting and unmounting seems strange => inspect
  // const [hasMounted, setHasMounted] = React.useState<boolean>(false);
  // React.useEffect(() => {
  //   console.log('eff');
  //   if (!hasMounted) {
  //     setHasMounted(true);
  //     console.log('PageLayoutComponent mount');
  //   }
  //   return () => {
  //     console.log('PageLayoutComponent unmount');
  //   };
  // }, [hasMounted, setHasMounted]);

  return (
    <Site
      fluid
      location={location}
      pageContext={pageContext}
      path={path}
      seo={
        {
          /** seoObject */
        }
      }
    >
      <MDXProvider components={getMDXComponentsConfig(props)}>{children}</MDXProvider>
    </Site>
  );
};

export default Page;
