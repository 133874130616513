import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/WK/Private/Projects/Gatsby/MKF/src/templates/Page.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box mdxType="Box">
  <div style={{
        backgroundColor: '#fff'
      }}>
    <h1>Jobs</h1>
    <h2>MKF – Mobiler Kranken Fahrservice</h2>
        <hr></hr>
        <p>{`Stellenangebote:
Wir suchen Krankenbeförderer in Voll- und Teilzeit
Voraussetzungen:
Führerscheinklasse B und mindestens 3 Jahre Fahrpraxis
Berufserfahrung
körperlich belastbar (schweres Tragen)
sauberes Führungszeugnis
Personenbeförderungsschein zwingend erforderlich
Ortskenntnisse
Deutsch in Wort und Schrift
zuverlässig
hilfsbereit
Falls Sie Interesse haben und die Voraussetzungen erfüllen Bewerbungsunterlagen schicken Sie bitte an: `}<a parentName="p" {...{
            "href": "mailto:info@knw-frankfurt.de"
          }}>{`info@knw-frankfurt.de`}</a></p>
  </div>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      