import * as React from 'react';

import {
  Accordion,
  AccordionProps,
  Avatar,
  AvatarProps,
  Background,
  BackgroundProps,
  BlockHead,
  BlockHeadProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Card,
  CardProps,
  Column,
  ColumnProps,
  ContentContainer,
  ContentContainerProps,
  Container,
  ContainerProps,
  Em,
  EmProps,
  Footer,
  FooterProps,
  Form,
  FormProps,
  Grid,
  GridProps,
  Icon,
  IconProps,
  IconCard,
  IconCardProps,
  Intro,
  IntroProps,
  Image,
  ImageProps,
  Link,
  LinkProps,
  Navigation,
  NavigationProps,
  ScrollToTop,
  ScrollToTopProps,
  Span,
  SpanProps,
  TextInputField,
  TextInputFieldProps,
  Paper,
  PaperProps,
  Row,
  RowProps,
  Typography,
  TypographyProps,
} from 'components';

// TODO: try dynamic imports from 'src/components'
/**
 * returns a React component to MDX tag mapping config
 * - injects global props then merges shallow with props injected from inside MDX declaration
 * - to render with MDXProvider
 * */
// TODO: optimize via useMemo or useCallback and check if most of the components needs prop injection at all?
// - mostly for better rendering performance while in dev mode
export const getMDXComponentsConfig = <T extends Record<string, unknown> = {}>(
  globalProps?: T,
): {
  wrapper: React.ReactNode;
} =>
  (({
    a: (p: LinkProps) => <Link {...{ ...globalProps, ...p }} />,
    Accordion: (p: AccordionProps) => <Accordion {...{ ...globalProps, ...p }} />,
    Avatar: (p: AvatarProps) => <Avatar {...{ ...globalProps, ...p }} />,
    Background: (p: BackgroundProps) => <Background {...{ ...globalProps, ...p }} />,
    BlockHead: (p: BlockHeadProps) => <BlockHead {...{ ...globalProps, ...p }} />,
    Box: (p: BoxProps) => <Box {...{ ...globalProps, ...p }} />,
    Button: (p: ButtonProps) => <Button {...{ ...globalProps, ...p }} />,
    Card: (p: CardProps) => <Card {...{ ...globalProps, ...p }} />,
    Col: (p: ColumnProps) => <Column {...{ ...globalProps, ...p }} />,
    Column: (p: ColumnProps) => <Column {...{ ...globalProps, ...p }} />,
    Contact: (p) => <BlockHead {...{ ...globalProps, ...p }} />,
    ContentContainer: (p: ContentContainerProps) => (
      <ContentContainer {...{ ...globalProps, ...p }} />
    ),
    Container: (p: ContainerProps) => <Container {...{ ...globalProps, ...p }} />,
    em: (p: EmProps) => <Em {...{ ...globalProps, ...p }} />,
    Footer: (p: FooterProps) => <Footer {...{ ...globalProps, ...p }} />,
    Form: (p: FormProps) => <Form {...{ ...globalProps, ...p }} />,
    Grid: (p: GridProps) => <Grid {...{ ...globalProps, ...p }} />,
    Icon: (p: IconProps) => <Icon {...{ ...globalProps, ...p }} />,
    IconCard: (p: IconCardProps) => <IconCard {...{ ...globalProps, ...p }} />,
    Intro: (p: IntroProps) => <Intro {...{ ...globalProps, ...p }} />,
    Image: (p: ImageProps) => <Image {...{ ...globalProps, ...p }} />,
    Link: (p: LinkProps) => <Link {...{ ...globalProps, ...p }} />,
    Navigation: (p: NavigationProps) => <Navigation {...{ ...globalProps, ...p }} />,
    Row: (p: RowProps) => <Row {...{ ...globalProps, ...p }} />,
    ScrollToTop: (p: ScrollToTopProps) => <ScrollToTop {...{ ...globalProps, ...p }} />,
    Span: (p: SpanProps) => <Span {...{ ...globalProps, ...p }} />,
    TextInputField: (p: TextInputFieldProps) => <TextInputField {...{ ...globalProps, ...p }} />,
    Paper: (p: PaperProps) => <Paper {...{ ...globalProps, ...p }} />,
    Typography: (p: TypographyProps) => <Typography {...{ ...globalProps, ...p }} />,
  } as React.ReactNode) as {
    wrapper: React.ReactNode /** WTF weird casting for MDXProvider */;
  });
